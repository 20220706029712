<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Store Management</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <!-- //SEARCH CARD HERE -->
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_VIEW_STORE_EVENT_LOG')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="tableList"
              :page.sync="page"
              :items-per-page="rowsPerPage"
              :loading="loading"
              hide-default-footer
              class="elevation-2 mt-4 px-4"
              data-cy="data-table"
            >
              <template v-slot:[`item.controls`]="{ item }">
                  <v-icon
                    medium
                    color="green"
                    class="mr-2"
                    @click="view(item)"
                  >
                    mdi-magnify-plus
                  </v-icon>
                  <v-icon
                    :disabled="item.enabled === true"
                    medium
                    color="green"
                    class="mr-2"
                    @click="enable(item)"
                  >
                    mdi-play
                  </v-icon>
              </template>
              <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                      Found no results.
                  </v-alert>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="numberOfPages"
              ></v-pagination>
            </div>
          </div>
        </template>
    </div>
</v-app>
</template>

<script>
import CircuitBreakerAPI from '@/api/CircuitBreakerAPI.js'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'WalletSystem',
    components: {
        Loading,
        DetailsForm
    },
    watch: {
      page: {
          handler() {
              this.queryData();
          },
          deep: true
      }
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'roles',
            'authorities'
        ]),
    },
    data() {
        return {
            headers: [],
            tableList: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            formType: 'CREATE',
            selectedRow: {},
            numberOfPages: 0,
            page: 1,
            rowsPerPage: 5,
            details: {
                title: '',
                item: {}
            },
            query: {},
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
        }
    },
    async mounted() {
        this.setHeaders()
        console.log('2')
        this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.page = 1
            }
            let pageNumber = this.page - 1;
            try {
                const response = await CircuitBreakerAPI.getAllStores(pageNumber, this.rowsPerPage, this.query)
                if (!response || response.error) {
                    //error getting data
                    console.log(`${response.error}`)
                } else {
                  this.tableList = response.stores
                  this.numberOfPages = response.pageSize
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        showForm(what, item = {}) {
            this.formType = what
            this.selectedRow = item
            this.showFormDialog = true
        },
        closeDialog(type) {
          console.log(type)
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        save(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                // if (value.formType === 'CREATED') {
                //     this.loadingMessage = `Creating new Wallet System <strong><i>${value.code}</i></strong>`
                //     setTimeout(async () => {
                //         const createResponse = await API.createWalletSystem(value)
                //         console.log(createResponse)
                //         this.loadingFinished = true
                //         if (!createResponse || createResponse.error) {
                //             this.isHtml = false
                //             this.loadingMessage = createResponse.error
                //         } else {
                //             this.loadingMessage = `Successfully created Wallet System <strong><i>${value.code}</i></strong>`
                //             this.showFormDialog = false
                //             this.$refs.WalletSystemForm.resetForm()
                //         }
                //     }, 2000);

                // } else if (value.formType === 'UPDATE') {
                //     this.loadingMessage = `Updating new Wallet System <strong><i>${value.code}</i></strong>`
                //     setTimeout(async () => {
                //         const updateResponse = await API.updateWalletSystem(value)
                //         this.loadingFinished = true
                //         if (!updateResponse || updateResponse.error) {
                //             this.isHtml = false
                //             this.loadingMessage = updateResponse.error
                //         } else {
                //             this.loadingMessage = `Successfully updated Wallet System <strong><i>${value.code}</i></strong>`
                //             this.showFormDialog = false
                //             this.$refs.WalletSystemForm.resetForm()
                //         }
                //     }, 2000);
                // } else {
                //     this.loadingMessage = "Invalid command"
                //     this.loadingFinished = true
                // }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        view(item) {
            this.details.title = item.storeName
            this.details.item = item
            this.isShowDetails = true
        },
        enable(item) {
          console.log(item)
          this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (item) {
              this.loadingMessage = `Enabling store <strong><i>${item.storeName ?? item.storeNo}</i></strong>`
              setTimeout(async () => {
                  const response = await CircuitBreakerAPI.enableStore(item)
                  console.log(response)
                  this.loadingFinished = true
                  if (!response || response.error) {
                      this.isHtml = false
                      this.loadingMessage = response.error
                  } else {
                      this.loadingMessage = `Successfully enabled store <strong><i>${item.storeName ?? item.storeNo}</i></strong>`
                  }
              }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        search(event, type) {
            this.query[type] = event
        },
        setHeaders() {
            var headers = [{
                    text: 'id',
                    value: 'id',
                    align: 'center',
                },
                {
                    text: 'Store No',
                    value: 'storeNo',
                    align: 'center',
                },
                {
                    text: 'Store Name',
                    value: 'storeName',
                    align: 'center',
                },
                {
                    text: 'Is Enabled',
                    value: 'enabled',
                    align: 'center',
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    align: 'center',
                    sortable: false
                }
            ]
            this.headers = headers
        },
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
